:root {
  --fg-color: rgb(0, 0, 0) !important;
  --fg-color-0: rgba(55, 53, 47, 0.09);
  --fg-color-1: rgba(55, 53, 47, 0.16);
  --fg-color-2: rgba(55, 53, 47, 0.4);
  --fg-color-3: rgba(55, 53, 47, 0.6);
  --fg-color-4: #000;
  --fg-color-5: rgba(55, 53, 47, 0.024);
  --fg-color-6: rgba(55, 53, 47, 0.8);
  --fg-color-icon: var(--fg-color);

  --bg-color: #fff;
  --bg-color-0: rgba(135, 131, 120, 0.15);
  --bg-color-1: rgb(247, 246, 243);
  --bg-color-2: rgba(135, 131, 120, 0.15);

  --select-color-0: rgb(46, 170, 220);
  --select-color-1: rgba(45, 170, 219, 0.3);
  --select-color-2: #ffffff;

  --notion-red: rgb(224, 62, 62);
  --notion-pink: rgb(173, 26, 114);
  --notion-blue: rgb(11, 110, 153);
  --notion-purple: rgb(105, 64, 165);
  --notion-teal: rgb(15, 123, 108);
  --notion-yellow: rgb(223, 171, 1);
  --notion-orange: rgb(217, 115, 13);
  --notion-brown: rgb(100, 71, 58);
  --notion-gray: rgb(155, 154, 151);

  --notion-red_background: rgb(251, 228, 228);
  --notion-pink_background: rgb(244, 223, 235);
  --notion-blue_background: rgb(221, 235, 241);
  --notion-purple_background: rgb(234, 228, 242);
  --notion-teal_background: rgb(221, 237, 234);
  --notion-yellow_background: rgb(251, 243, 219);
  --notion-orange_background: rgb(250, 235, 221);
  --notion-brown_background: rgb(233, 229, 227);
  --notion-gray_background: rgb(241 241 239);
  --notion-green_background: rgb(219, 237, 219);
  --notion-default_background: rgba(227, 226, 224);

  --notion-red_background_co: rgba(251, 228, 228, 0.3);
  --notion-pink_background_co: rgba(244, 223, 235, 0.3);
  --notion-blue_background_co: rgba(221, 235, 241, 0.3);
  --notion-purple_background_co: rgba(234, 228, 242, 0.3);
  --notion-teal_background_co: rgba(221, 237, 234, 0.3);
  --notion-yellow_background_co: rgba(251, 243, 219, 0.3);
  --notion-orange_background_co: rgba(250, 235, 221, 0.3);
  --notion-brown_background_co: rgba(233, 229, 227, 0.3);
  --notion-gray_background_co: rgba(241, 241, 239, 0.3);
  --notion-green_background_co: rgba(219, 237, 219, 0.3);
  --notion-default_background_co: rgba(227, 226, 224, 0.3);

  --notion-item-blue: rgba(0, 120, 223, 0.2);
  --notion-item-orange: rgba(245, 93, 0, 0.2);
  --notion-item-green: rgba(0, 135, 107, 0.2);
  --notion-item-pink: rgba(221, 0, 129, 0.2);
  --notion-item-brown: rgba(140, 46, 0, 0.2);
  --notion-item-red: rgba(255, 0, 26, 0.2);
  --notion-item-yellow: rgba(233, 168, 0, 0.2);
  --notion-item-default: rgba(206, 205, 202, 0.5);
  --notion-item-purple: rgba(103, 36, 222, 0.2);
  --notion-item-gray: rgba(155, 154, 151, 0.4);

  --notion-max-width: 720px;
  --notion-header-height: 45px;
}

.notion {
  font-size: 16px;
  line-height: 1.5;
  color: var(--fg-color);
  caret-color: var(--fg-color);
  font-family: inherit !important;
}

.notion > * {
  padding: 3px 0;
}

.notion * {
  margin-block-start: 0;
  margin-block-end: 0;
}

.notion *::selection {
  background: var(--select-color-1);
}

.notion *,
.notion *:focus {
  outline: 0;
}

.notion-page-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1300px) and (min-height: 300px) {
  .notion-page-content-has-aside {
    display: flex;
    flex-direction: row;
    width: calc((100vw + var(--notion-max-width)) / 2);
  }

  .notion-page-content-has-aside .notion-aside {
    display: flex;
  }

  .notion-page-content-has-aside .notion-page-content-inner {
    width: var(--notion-max-width);
    max-width: var(--notion-max-width);
  }
}

.notion-page-content-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.notion-aside {
  position: sticky;
  top: calc(88px);
  align-self: flex-start;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.notion-aside-table-of-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(100vh - 148px - 16px);
  overflow: hidden auto;
  min-width: 222px;
  overflow: auto;
}

.notion-aside-table-of-contents-header {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.1em;
  word-break: break-word;
}

.notion-aside-table-of-contents .notion-table-of-contents-item {
  line-height: 1;
}

.notion-aside-table-of-contents
  .notion-table-of-contents-item-indent-level-0:first-of-type {
  margin-top: 0;
}

.notion-aside-table-of-contents .notion-table-of-contents-item-indent-level-0 {
  margin-top: 0.25em;
}

.notion-aside-table-of-contents .notion-table-of-contents-item-indent-level-1 {
  font-size: 13px;
}

.notion-aside-table-of-contents .notion-table-of-contents-item-indent-level-2 {
  font-size: 12px;
}

.notion-aside-table-of-contents .notion-table-of-contents-item-body {
  border: 0 none;
}

.notion-table-of-contents-active-item {
  color: var(--select-color-2) !important;
}

.notion-app {
  position: relative;
  background: var(--bg-color);
  min-height: 100vh;
}

.notion-viewport {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -10;
  pointer-events: none;
}

.medium-zoom-overlay {
  z-index: 300;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.medium-zoom-image {
  border-radius: 0;
}

.medium-zoom-image--opened {
  z-index: 301;
  /* width: auto !important; */
}

@media (max-width: 768px) {
  .medium-zoom-image--opened {
    object-fit: fill !important;
    height: auto !important;
  }
}

@media (min-width: 768px) {
  .medium-zoom-image--opened {
    object-fit: scale-down !important;
  }
}

.notion-frame {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.notion-page-scroller {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  min-height: calc(100vh - var(--notion-header-height));
}

.notion-red,
.notion-red_co {
  color: var(--notion-red);
}
.notion-pink,
.notion-pink_co {
  color: var(--notion-pink);
}
.notion-blue,
.notion-blue_co {
  color: var(--notion-blue);
}
.notion-purple,
.notion-purple_co {
  color: var(--notion-purple);
}
.notion-teal,
.notion-teal_co {
  color: var(--notion-teal);
}
.notion-yellow,
.notion-yellow_co {
  color: var(--notion-yellow);
}
.notion-orange,
.notion-orange_co {
  color: var(--notion-orange);
}
.notion-brown,
.notion-brown_co {
  color: var(--notion-brown);
}
.notion-gray,
.notion-gray_co {
  color: var(--notion-gray);
}
.notion-red_background {
  background-color: var(--notion-red_background);
}
.notion-pink_background {
  background-color: var(--notion-pink_background);
}
.notion-blue_background {
  background-color: var(--notion-blue_background);
}
.notion-purple_background {
  background-color: var(--notion-purple_background);
}
.notion-teal_background {
  background-color: var(--notion-teal_background);
}
.notion-yellow_background {
  background-color: var(--notion-yellow_background);
}
.notion-orange_background {
  background-color: var(--notion-orange_background);
}
.notion-brown_background {
  background-color: var(--notion-brown_background);
}
.notion-gray_background {
  background-color: var(--notion-gray_background);
}
.notion-green_background {
  background-color: var(--notion-green_background);
}
.notion-default_background {
  background-color: var(--notion-default_background);
}

.notion-red_background_co {
  background-color: var(--notion-red_background_co);
}
.notion-pink_background_co {
  background-color: var(--notion-pink_background_co);
}
.notion-blue_background_co {
  background-color: var(--notion-blue_background_co);
}
.notion-purple_background_co {
  background-color: var(--notion-purple_background_co);
}
.notion-teal_background_co {
  background-color: var(--notion-teal_background_co);
}
.notion-yellow_background_co {
  background-color: var(--notion-yellow_background_co);
}
.notion-orange_background_co {
  background-color: var(--notion-orange_background_co);
}
.notion-brown_background_co {
  background-color: var(--notion-brown_background_co);
}
.notion-gray_background_co {
  background-color: var(--notion-gray_background_co);
}
.notion-green_background_co {
  background-color: var(--notion-green_background_co);
}
.notion-default_background_co {
  background-color: var(--notion-default_background_co);
}

.notion-item-blue {
  background-color: var(--notion-item-blue);
}
.notion-item-orange {
  background-color: var(--notion-item-orange);
}
.notion-item-green {
  background-color: var(--notion-item-green);
}
.notion-item-pink {
  background-color: var(--notion-item-pink);
}
.notion-item-brown {
  background-color: var(--notion-item-brown);
}
.notion-item-red {
  background-color: var(--notion-item-red);
}
.notion-item-yellow {
  background-color: var(--notion-item-yellow);
}
.notion-item-default {
  background-color: var(--notion-item-default);
}
.notion-item-purple {
  background-color: var(--notion-item-purple);
}
.notion-item-gray {
  background-color: var(--notion-item-gray);
}

.notion b {
  font-weight: 600;
}

.notion-title {
  width: 100%;
  font-size: 2.5em;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.2;
}

summary > .notion-h {
  display: inline;
}

.notion-h {
  position: relative;
  font-weight: 600;
  line-height: 1.3;
  padding: 3px 2px;
  margin-bottom: 1px;
  max-width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
}

.notion-h1 {
  font-size: 1.575em;
  margin-top: 1.08em;
  @apply w-full;
  /* @apply border-b w-full; */
}
.notion-h2 {
  @apply w-full;
}
.notion-h3 {
  @apply w-full;
}

.notion-header-anchor {
  position: absolute;
  top: -54px;
  left: 0;
}

.notion-title + .notion-h1,
.notion-title + .notion-h2,
.notion-title + .notion-h3 {
  margin-top: 0;
}

.notion-h1:first-child {
  margin-top: 0;
}
/* .notion-h1:first-of-type {
  margin-top: 2px;
} */
.notion-h2 {
  font-size: 1.4em;
  margin-top: 1.1em;
}
.notion-h3 {
  font-size: 1.25em;
  margin-top: 1em;
}

.notion-h:hover .notion-hash-link {
  opacity: 1;
  @apply dark:fill-gray-200;
}

.notion-hash-link {
  opacity: 0;
  text-decoration: none;
  float: left;
  margin-left: -20px;
  padding-right: 4px;
  fill: var(--fg-color-icon);
}

.notion-page-cover {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 30vh;
  min-height: 30vh;
  max-height: 30vh;
  padding: 0;
}

.notion-page {
  position: relative;
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
}

.notion-full-page {
  padding-bottom: calc(max(10vh, 120px));
}

.notion-page-no-cover {
  margin-top: 48px !important;
  padding-top: 96px;
}

.notion-page-no-cover.notion-page-no-icon {
  padding-top: 0;
}

.notion-page-no-cover.notion-page-has-image-icon {
  padding-top: 148px;
}

.notion-page-has-cover.notion-page-no-icon {
  padding-top: 48px;
}

.notion-page-has-cover {
  padding-top: 112px;
}

.notion-page-has-cover.notion-page-has-text-icon {
  padding-top: 64px;
}

.notion-page-icon-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.notion-page-icon-wrapper .notion-page-icon {
  position: relative;
  display: block;
}

.notion-page {
  /* width: var(--notion-max-width); */
  width: 100% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  /* padding-left: calc(min(12px, 8vw)); */
  /* padding-right: calc(min(12px, 8vw)); */
}

.notion-full-width {
  --notion-max-width: calc(min(1920px, 98vw));
  padding-left: calc(min(96px, 8vw));
  padding-right: calc(min(96px, 8vw));
}

.notion-small-text {
  font-size: 14px;
}

.notion-quote {
  display: block;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  border-left: 3px solid currentcolor;
  padding: 0.2em 0.9em;
  margin: 6px 0;
  font-size: 1.2em;
}

.notion-hr {
  width: 100%;
  margin: 6px 0;
  padding: 0;
  border-top: 1px solid #e5e7eb !important;
  /* border-bottom-width: 1px; */
}

.notion-link {
  color: inherit;
  word-break: break-word;
  text-decoration: inherit;
  border-bottom: 0.05em solid !important;
  border-color: var(--fg-color-2);
  opacity: 0.7;
  transition:
    border-color 100ms ease-in,
    opacity 100ms ease-in;
}

.notion-link:hover {
  border-color: var(--fg-color-6);
  opacity: 1;
}

.notion-blank {
  width: 100%;
  min-height: 1rem;
  padding: 3px 2px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.notion-page-link {
  display: flex;
  color: var(--fg-color);
  text-decoration: underline;
  width: 100%;
  height: 30px;
  margin: 1px 0;
  transition: background 120ms ease-in 0s;
  /* pointer-events: none; */
}

.notion-page-link:hover {
  /*background: var(--bg-color-0);*/
}

.notion-page-icon {
  font-family: 'Apple Color Emoji', 'Segoe UI Emoji', NotoColorEmoji,
    'Noto Color Emoji', 'Segoe UI Symbol', 'Android Emoji', EmojiSymbols;
  font-size: 1.1em;
  margin: 2px 4px 0 2px;
  fill: var(--fg-color-6);
  color: var(--fg-color-icon);
  @apply dark:fill-gray-200;
}

img.notion-page-icon,
svg.notion-page-icon {
  display: block;
  object-fit: fill;
  border-radius: 3px;
  /* padding: 1px; */
  max-width: 22px;
  max-height: 22px;
}

.notion-icon {
  display: block;
  width: 18px;
  height: 18px;
  color: var(--fg-color-icon);
}

.notion-page-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  line-height: 1.3;
  border-bottom: 1px solid var(--fg-color-1);
  margin: 4px 0;
}

.notion-inline-code {
  color: #eb5757;
  padding: 0.2em 0.4em;
  background: var(--bg-color-2);
  border-radius: 3px;
  font-size: 85%;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
    monospace;
}

.notion-inline-underscore {
  text-decoration: underline;
}

.notion-list {
  margin: 0;
  margin-block-start: 0.6em;
  margin-block-end: 0.6em;
  @apply w-full;
}

.notion-list-disc {
  list-style-type: disc;
  padding-inline-start: 1.4em;
  margin-top: 0;
  margin-bottom: 0;
}

.notion-list-numbered {
  list-style-type: decimal;
  padding-inline-start: 1.6em;
  margin-top: 0;
  margin-bottom: 0;
}

.notion-list-numbered > .notion-list-numbered {
  list-style-type: lower-alpha;
}

.notion-list-numbered > .notion-list-numbered > .notion-list-numbered {
  list-style-type: lower-roman;
}

.notion-list-disc li {
  padding-left: 0.1em;
}

.notion-list-numbered li {
  padding-left: 0.2em;
}

.notion-list li {
  padding: 1px 0;
  white-space: pre-wrap;
}

.notion-asset-wrapper {
  margin: 0.5rem 0;
  max-width: 100%;
  min-width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.notion-asset-wrapper-image {
  max-width: 100%;
}

.notion-asset-wrapper-image > div {
  height: auto !important;
}

.notion-asset-wrapper-full {
  max-width: inherit;
}

.notion-asset-wrapper img {
  /* width: 90%; */
  /* height: 100%; */
  height: auto !important;
  max-height: 100%;
  margin: auto;
}

.notion-asset-wrapper iframe {
  border: none;
  background: rgb(247, 246, 245);
}

.notion-text {
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  padding: 3px 2px !important;
  margin: 1px 0 !important;
}

.notion-text:first-child {
  margin-top: 2px;
}

.notion-text-children {
  padding-left: 1.5em;
  display: flex;
  flex-direction: column;
}

.notion-block {
  padding: 3px 2px;
}

.notion .notion-code {
  font-size: 85%;
  margin-top: 0;
  margin-bottom: 0.5em;
}

pre[class*='language-'] {
  line-height: inherit;
}

code[class*='language-'] {
  background: unset !important;
}

.notion-code {
  padding: 30px 16px 30px 20px;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  tab-size: 2;
  display: block;
  box-sizing: border-box;
  overflow: auto;
  font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier,
    monospace;
  @apply w-full mb-0 dark:bg-black !important;
}

.notion-column {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 12px;
}

.notion-column > *:first-child {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.notion-column > *:last-child {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}

.notion-row {
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}

@media (max-width: 640px) {
  .notion-row {
    flex-direction: column;
  }

  .notion-row .notion-column {
    width: 100% !important;
  }

  .notion-row .notion-spacer {
    display: none;
  }
}

.notion-bookmark {
  margin: 4px 0;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  border: 1px solid var(--fg-color-1);
  border-radius: 3px;
  display: flex;
  overflow: hidden;
  user-select: none;
}

.notion-bookmark > div:first-child {
  flex: 4 1 180px;
  padding: 12px 14px 14px;
  overflow: hidden;
  text-align: left;
  color: var(--fg-color);
}

.notion-bookmark-title {
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 24px;
  margin-bottom: 2px;
}

.notion-bookmark-description {
  font-size: 12px;
  line-height: 16px;
  opacity: 0.8;
  height: 32px;
  overflow: hidden;
}

.notion-bookmark-link {
  display: flex;
  margin-top: 6px;
  @apply w-52 md:w-80;
}

.notion-bookmark-link > img {
  width: 16px;
  height: 16px;
  min-width: 16px;
  margin-right: 6px;
}

.notion-bookmark-link > div {
  font-size: 12px;
  line-height: 16px;
  color: var(--fg-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notion-bookmark-image {
  flex: 1 1 180px;
  position: relative;
}

.notion-bookmark-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

.notion-column .notion-bookmark-image {
  display: none;
}

.notion-spacer {
  width: calc(min(32px, 4vw));
}

.notion-spacer:last-child {
  display: none;
}

.notion-asset-object-fit {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 1px;
}

.notion-image {
  display: block;
  width: 100%;
  border-radius: 1px;
}

.notion-asset-caption {
  padding: 6px 0 6px 2px;
  white-space: pre-wrap;
  word-break: break-word;
  caret-color: var(--fg-color);
  font-size: 14px;
  line-height: 1.4;
  color: var(--fg-color-3);
  @apply dark:text-gray-300;
}

.notion-callout {
  padding: 16px 16px 16px 12px;
  display: inline-flex;
  width: 100%;
  border-radius: 3px;
  border-width: 1px;
  align-items: center;
  box-sizing: border-box;
  margin: 4px 0;
  border: 1px solid var(--fg-color-0);
}

.dark-mode .notion-callout {
  border-color: var(--bg-color-2);
}

.notion-callout .notion-page-icon {
  align-self: flex-start;
  width: 24px;
  height: 24px;
  font-size: 1em;
  line-height: 1em;
}

.notion-callout-text {
  margin-left: 8px;
  white-space: pre-wrap;
  word-break: break-word;
  overflow: hidden;
}

.notion-callout-text .notion-text{
  padding: 0px 0px !important;
  margin: 0px 0px !important;
  line-height: normal !important;
}

.notion-toggle {
  padding: 3px 2px;
  width: 100%;
}
.notion-toggle > summary {
  cursor: pointer;
  outline: none;
}
.notion-toggle > div {
  margin-left: 1.1em;
}

.notion-list-view {
  position: relative;
  padding-left: 0;
  transition: padding 200ms ease-out;
  max-width: 100%;
}

.notion-list-body {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--fg-color-1);
  padding-top: 8px;
  max-width: 100%;
  overflow: hidden;
}

.notion-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
  margin: 1px 0;
  max-width: 100%;
  overflow: hidden;
}

.notion-list-item-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  line-height: 1.3;
}

.notion-list-item-body {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
}

.notion-list-item-property {
  /* display: flex;
  align-items: center; */
  margin-left: 14px;
  font-size: 14px;
}

.notion-list-item-property .notion-property-date,
.notion-list-item-property .notion-property-created_time,
.notion-list-item-property .notion-property-last_edited_time,
.notion-list-item-property .notion-property-url {
  display: inline-block;
  color: var(--fg-color-3);
  font-size: 12px;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.notion-board {
  width: 100vw;
  max-width: 100vw;
  align-self: center;
  overflow: auto hidden;
}

.notion-board-view {
  position: relative;
  float: left;
  min-width: 100%;
  padding-left: 0;
  transition: padding 200ms ease-out;
}

.notion-board-header {
  display: flex;
  position: absolute;
  z-index: 30;
  height: 44px;
  min-width: 100%;
}

.notion-board-header-inner {
  display: inline-flex;
  border-top: 1px solid var(--fg-color-1);
  border-bottom: 1px solid var(--fg-color-1);
}

.notion-board-header-placeholder {
  height: var(--notion-header-height);
}

.notion-board-th {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-right: 16px;
  box-sizing: content-box;
  flex-shrink: 0;
}

.notion-board-th-body {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.2;
  padding-left: 2px;
  padding-right: 4px;
  white-space: nowrap;
  overflow: hidden;
}

.notion-board-th-count {
  color: var(--fg-color-3);
  font-weight: 500;
  padding: 0 8px;
}

.notion-board-th-empty {
  margin-right: 4px;
  position: relative;
  top: 2px;
}

.notion-board-body {
  display: inline-flex;
}

.notion-board-group {
  flex: 0 0 auto;
  padding-right: 16px;
  box-sizing: content-box;
}

.notion-board-group-card {
  margin-bottom: 8px;
}

.notion-board-view .notion-board-th,
.notion-board-view .notion-board-group {
  width: 260px;
}

.notion-board-view-size-small .notion-board-th,
.notion-board-view-size-small .notion-board-group {
  width: 180px;
}

.notion-board-view-size-large .notion-board-th,
.notion-board-view-size-large .notion-board-group {
  width: 320px;
}

.notion-table-of-contents {
  width: 100%;
  margin: 4px 0;
  @apply bg-gray-50 dark:bg-gray-900 p-2;
}

.notion-table-of-contents-item {
  text-decoration: none;
  user-select: none;
  transition: background 20ms ease-in 0s;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;

  padding: 6px 2px;
  font-size: 15px;
  line-height: 1.2;
  display: flex;
  align-items: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @apply dark:text-white;
}

.notion-table-of-contents-item:hover {
  background: var(--bg-color-0);
}

.notion-table-of-contents-item-body {
  border-bottom: 1px solid var(--fg-color-1);
}

.notion-to-do {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.notion-to-do-item {
  width: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 2px;
  min-height: calc(1.5em + 3px + 3px);
}

.notion-to-do-children {
  padding-left: 1.5em;
}

.notion-to-do-checked {
  text-decoration: line-through;
  opacity: 0.375;
}

.notion-to-do-body {
  white-space: pre-wrap;
  word-break: break-word;
}

.notion-to-do-item .notion-property-checkbox {
  margin-right: 8px;
  /* @apply w-4 h-4 border-2 */
}

.notion-property-checkbox-checked {
  /* @apply bg-white */
}

.notion-google-drive {
  width: 100%;
  align-self: center;
  margin: 4px 0;
}

.notion-google-drive-link {
  position: relative;
  display: flex;
  flex-direction: column;
  color: inherit;
  text-decoration: none;
  width: 100%;
  border: 1px solid var(--fg-color-1);
  border-radius: 3px;

  user-select: none;
  transition: background 20ms ease-in 0s;
  cursor: pointer;
}

.notion-google-drive-link:hover {
  background: var(--bg-color-0);
}

.notion-google-drive-preview {
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 55%;
  overflow: hidden;
}

.notion-google-drive-preview img {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
  object-position: center top;
}

.notion-google-drive-body {
  width: 100%;
  min-height: 60px;
  padding: 12px 14px 14px;
  overflow: hidden;
  border-top: 1px solid var(--fg-color-1);
}

.notion-google-drive-body-title {
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2px;
}

.notion-google-drive-body-modified-time {
  font-size: 12px;
  line-height: 1.3;
  color: var(--fg-color-3);
  max-height: 32px;
  overflow: hidden;
}

.notion-google-drive-body-source {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.notion-google-drive-body-source-icon {
  flex-shrink: 0;
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.notion-google-drive-body-source-domain {
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notion-file {
  width: 100%;
  margin: 1px 0;
}

.notion-file-link {
  display: flex;
  align-items: center;
  padding: 3px 2px;
  border-radius: 3px;
  transition: background 20ms ease-in 0s;
  color: inherit;
  text-decoration: none;

  @apply dark:stroke-slate-200;
}

.notion-file-link:hover {
  background: var(--bg-color-0);
}

.notion-file-icon {
  margin-right: 2px;
  width: 1.35em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: calc(1.5em + 3px + 3px);
  height: 1.35em;
}

.notion-file-info {
  display: flex;
  align-items: baseline;
  overflow-x: auto;
}

.notion-file-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notion-file-size {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* color: var(--fg-color-3); */
  font-size: 12px;
  line-height: 16px;
  margin-left: 6px;

  @apply dark:text-gray-400 !important;
}

.notion-audio {
  width: 100%;
}

.notion-audio audio {
  width: 100%;
}

.notion-equation {
  position: relative;
  display: inline-flex;
  color: inherit;
  fill: inherit;
  user-select: none;
  border-radius: 3px;
  transition: background 20ms ease-in 0s;
  text-align: center;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none; /* firefox */
}

.notion-equation-inline {
  -webkit-user-select: all;
  -moz-user-select: all;
  user-select: all;
}

.notion-equation-block {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  max-width: 100%;
  padding: 4px 8px;
  margin: 4px 0;
  cursor: pointer;
}

.notion-equation:hover {
  background: var(--bg-color-0);
}

.notion-equation:active,
.notion-equation:focus {
  background: var(--select-color-2);
}

.notion-frame .katex-display .katex {
  padding-right: 32px;
}

.notion-frame .katex > .katex-html {
  white-space: normal;
}

.katex-display > .katex > .katex-html > .tag {
  position: inherit !important;
}

.notion-page-title {
  display: inline-flex;
  max-width: 100%;
  align-items: center;
  line-height: 1.3;
  transition: background 120ms ease-in 0s;
}

.notion-page-title-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  border-radius: 3px;
  flex-shrink: 0;
  margin-left: 2px;
  margin-right: 6px;
}

.notion-collection {
  @apply max-w-0;
}

.notion-collection-card {
  /* cursor: default !important; */
}

.notion-collection-card-property .notion-link {
  border-bottom: 0 none;
  cursor: pointer;
}

.notion-collection-card-property .notion-page-title {
  transition: none;
}

.notion-collection-card-property .notion-page-title:hover {
  background: unset;
}

.notion-collection-card-property .notion-page-title-icon {
  margin-left: 0;
  height: 18px;
  width: 18px;
}

.notion-collection-card-property .notion-page-title-text {
  border-bottom: 0 none;
}

.notion-collection-card-property
  .notion-property-relation
  .notion-page-title-text {
  border-bottom: 1px solid;
}

.notion-page-title-text {
  position: relative;
  top: 1px;
  border-bottom: 1px solid var(--fg-color-1);
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  @apply dark:text-gray-200;
}

.notion-collection-row {
  @apply hidden;
}

.notion-collection-row-body {
  display: flex;
  flex-direction: column;
}

.notion-collection-row-property {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.notion-collection-row-value {
  flex: 1;
  padding: 6px 8px 7px;
  font-size: 14px;
}

.notion-collection-row-property .notion-collection-column-title {
  display: flex;
  align-items: center;
  width: 160px;
  height: 34px;
  color: var(--fg-color-3);
  padding: 0 6px;
}

.notion-collection-row-property .notion-property {
  width: 100%;
}

.notion-collection-row-property .notion-collection-column-title-icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
}

.notion-collection-row-property .notion-link {
  border-bottom: 0 none;
}

.notion-collection-row-property
  .notion-property-relation
  .notion-page-title-text {
  border-bottom: 1px solid;
}

.notion-user {
  display: block;
  object-fit: cover;
  border-radius: 100%;
  width: 20px;
  height: 20px;
}

.notion-list-item-property .notion-property-multi_select-item {
  margin-bottom: 0;
  flex-wrap: none;
}

.notion-list-item-property .notion-property-multi_select-item:last-of-type {
  margin-right: 0;
}

.notion-toggle .notion-collection-header,
.notion-toggle .notion-table-view,
.notion-toggle .notion-board-view,
.notion-column .notion-collection-header,
.notion-column .notion-table-view,
.notion-column .notion-board-view {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.notion-toggle .notion-table,
.notion-toggle .notion-board,
.notion-column .notion-table,
.notion-column .notion-board {
  width: 100% !important;
  max-width: 100% !important;
}

@media only screen and (max-width: 730px) {
  .notion-page {
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .notion-asset-wrapper {
    max-width: 100%;
  }

  .notion-asset-wrapper-full {
    max-width: inherit;
  }
}

@media (max-width: 640px) {
  .notion-bookmark-image {
    display: none;
  }
}

.lazy-image-wrapper {
  position: relative;
  overflow: hidden;
}

.lazy-image-wrapper img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
}

.lazy-image-preview {
  filter: blur(20px);
  transform: scale(1.1);

  opacity: 1;
  transition: opacity 400ms ease-in !important;
  transition-delay: 100ms;
  will-change: opacity;
}

.lazy-image-wrapper img.lazy-image-real {
  position: relative;
}

.lazy-image-real {
  opacity: 0;
  transition: opacity 400ms ease-out !important;
  will-change: opacity;
}

.lazy-image-real.medium-zoom-image {
  transition:
    transform 0.3s cubic-bezier(0.2, 0, 0.2, 1),
    opacity 400ms ease-out !important;
  will-change: opacity, transform;
}

.medium-zoom-image--opened {
  object-fit: cover;
  opacity: 1;
}

/* NOTE: if we hide the preview image, there's a weird bug with react hydration where
   the image will sometimes flicker to show the background during initial page load.
   So I'm removing this `opacity: 0` for now, but it will cause issues if the real
   image is transparent. */
.lazy-image-loaded .lazy-image-preview {
  opacity: 0;
}

.lazy-image-loaded .lazy-image-real {
  opacity: 1;
}

.notion-page-cover.lazy-image-wrapper {
  padding: 0 !important;
}

.notion-collection-card-cover .lazy-image-wrapper {
  padding: 0 !important;
  z-index: 20;
  height: 100%;
}

.notion-page-cover .lazy-image-preview,
.notion-page-cover .lazy-image-real {
  will-change: unset !important;
}

.notion-page-cover .lazy-image-loaded .lazy-image-preview {
  opacity: 1;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/* NOTION CSS OVERRIDE */
.notion {
  @apply dark:text-gray-100;
  overflow-wrap: break-word;
}
.notion,
.notion-text,
.notion-quote,
.notion-h-title {
  @apply leading-8;
  @apply p-0;
  @apply my-3;
}
.notion-page-link {
  color: inherit;
}

.notion-callout svg.notion-page-icon {
  @apply hidden;
}

svg + .notion-page-title-text {
  @apply border-b-0;
}

.notion-bookmark {
  @apply border-2;
  @apply border-gray-100;
  color: inherit;
}

.notion-bookmark .notion-bookmark-title,
.notion-bookmark .notion-bookmark-link div {
  @apply text-gray-900 dark:text-gray-200;
}

.notion-bookmark .notion-bookmark-description {
  @apply text-gray-600 dark:text-gray-300;
}

.notion-gray_background,
.notion-brown_background,
.notion-orange_background,
.notion-yellow_background,
.notion-blue_background,
.notion-purple_background,
.notion-teal_background,
.notion-red_background,
.notion-pink_background {
  @apply dark:text-black;
}

.notion-bookmark:hover {
  @apply border-blue-400;
}
.notion-asset-caption {
  @apply text-center;
}
.notion-full-width {
  @apply px-0;
}
.notion-page {
  @apply w-auto;
  @apply px-0;
}
.notion-quote {
  padding: 0.2em 0.9em;
}

.notion-collection {
  align-self: center;
  min-width: 100%;
}

.notion-collection-header {
  display: flex;
  align-items: center;
  height: 42px;
  padding: 4px 2px;
  white-space: nowrap;
  overflow: hidden;
  @apply px-0 !important;
}

.notion-collection-header-title {
  display: inline-flex;
  align-items: center;
  font-size: 1.25em;
  line-height: 1.2;
  font-weight: 600;
  white-space: pre-wrap;
  word-break: break-word;
  margin-right: 0.5em;
}

.notion-collection-view-dropdown {
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 3px;
  transition: background 120ms ease-in 0s;
}

.notion-collection-view-dropdown:hover {
  background: var(--bg-color-0);
}

.notion-collection-view-dropdown-icon {
  position: relative;
  top: 2px;
  margin-left: 4px;
}

.notion-collection-view-type-menu-item {
  cursor: pointer;
}

.notion-collection-view-type-menu-item .notion-collection-view-type {
  width: 340px;
  max-width: 100%;
  min-width: 100px;
}

.notion-collection-view-type {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.notion-collection-view-type-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  fill: var(--fg-color);
  margin-right: 6px;
  @apply dark:fill-gray-200;
}

.notion-collection-view-type-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--fg-color);
  @apply dark:text-gray-200;
}

.notion-table {
  align-self: center;
  overflow: auto hidden;
  @apply w-full !important;
}

.notion-table-view {
  position: relative;
  float: left;
  min-width: var(--notion-max-width);
  padding-left: 0;
  transition: padding 200ms ease-out;
  @apply px-0 !important;
}

.notion-table-header {
  display: flex;
  position: absolute;
  z-index: 30;
  height: 33px;
  color: var(--fg-color-3);
  min-width: var(--notion-max-width);
}

.notion-table-header-inner {
  width: 100%;
  display: inline-flex;
  border-top: 1px solid var(--fg-color-1);
  border-bottom: 1px solid var(--fg-color-1);
  /* box-shadow: white -3px 0 0, rgba(55, 53, 47, 0.16) 0 1px 0; */
}

.notion-table-header-placeholder {
  height: 34px;
}

.notion-table-th {
  display: flex;
  position: relative;
}

.notion-table-view-header-cell {
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  height: 32px;
  font-size: 14px;
  padding: 0;
}

.notion-table-view-header-cell-inner {
  user-select: none;
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  border-right: 1px solid var(--fg-color-0);
}

.notion-table-th:last-child .notion-table-view-header-cell-inner {
  border-right: 0 none;
}

.notion-collection-column-title {
  display: flex;
  align-items: center;
  line-height: 120%;
  min-width: 0;
  font-size: 14px;
  @apply dark:text-gray-200;
}

.notion-collection-column-title-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  fill: var(--fg-color-2);
  margin-right: 6px;
  @apply dark:text-gray-200 dark:fill-gray-200;
}

.notion-collection-view-tabs-content-item-active {
  @apply dark:border-gray-300;
}

.notion-collection-column-title-body {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notion-table-body {
  position: relative;
  min-width: var(--notion-max-width);
}

.notion-table-row {
  display: flex;
  border-bottom: 1px solid var(--fg-color-1);
}

.notion-table-cell {
  min-height: 32px;
  padding: 5px 8px 6px;
  font-size: 14px;
  line-height: 1;
  white-space: normal;
  overflow: hidden;
  word-break: break-word;
  border-right: 1px solid var(--fg-color-1);
}

.notion-table-cell:last-child {
  border-right: 0 none;
}

.notion-table-cell-title {
  font-weight: 500;
}

.notion-table-cell-text {
  white-space: pre-wrap;
}

.notion-table-cell-text,
.notion-table-cell-number,
.notion-table-cell-url,
.notion-table-cell-email,
.notion-table-cell-phone_number {
  line-height: 1.5;
}

.notion-table-cell-number {
  white-space: pre-wrap;
}

.notion-table-cell-select,
.notion-table-cell-multi_select {
  padding: 7px 8px 0;
}

.notion-simple-table {
  @apply whitespace-nowrap overflow-x-auto block w-full border-0 !important;
}

.notion-asset-wrapper-pdf > div {
  display: block !important;
}

/* https://github.com/kchen0x */
.notion-quote {
  display: block;
  border-radius: 5px;
  border-color: var(--notion-blue);
  border-left-color: var(--notion-blue);
  background-color: var(--notion-blue_background_co);
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  border-left: 10px solid;
  padding: 0.2em 0.9em;
  margin: 6px 0;
  font-size: 1em;
  /* color: var(--notion-gray); */
}

/* .notion-asset-wrapper-pdf > div {
  width: unset !important;
} */

/* pdf预览适配页面 */
.react-pdf__Page__canvas,
.react-pdf__Page__textContent {
  width: 100% !important;
  height: auto !important;
}

/* simple table设置 */
table,
thead,
tbody {
  display: block;
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.notion-collection-card {
  @apply dark:text-gray-200 dark:bg-gray-800 dark:hover:bg-black;
}

.notion-code-copy {
  display: none;
}

pre[class*='language-mermaid'] {
  @apply bg-gray-50 dark:bg-gray-200 !important;
}

/* mermaid 原文隐藏 */
code.language-mermaid {
  display: none;
}

.mermaid .invisible{
  visibility: inherit;
}

.code-toolbar {
  @apply w-full shadow-md pb-0;
}

.mermaid > svg {
  margin: auto;
}

.notion-equation-inline .katex-display {
  margin: 0 0 !important;
}

.notion-external-title {
  @apply dark:text-white !important;
}

.notion-external-subtitle {
  @apply dark:text-gray-400 !important;
}

.notion-external-block {
  @apply dark:border-gray-200 !important;
}

.notion-external-image > svg > g > path {
  @apply dark:fill-gray-200 !important;
}

.notion-external-image {
  @apply w-6 h-6 mx-3 my-2 !important;
}

/* 表格 #f5f6f8*/
.notion-simple-table-row {
}

/* 表格头 */
.notion-simple-table tr:first-child td {
  background-color: #f5f6f8;
  @apply text-center font-bold dark:bg-gray-800 !important;
}

.notion-simple-table td {
  border: 1px solid var(#eee) !important;
}

/* 视频尺寸bug */
figure.notion-asset-wrapper.notion-asset-wrapper-video > div {
  height: 100% !important;
  width: 100% !important;
}
